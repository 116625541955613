import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component';

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our mortgage products</h1>
    <p>With effect from Monday 6 June 2022 we will be making the following changes to our range:</p>
    <p>We have introduced an exciting enhancement to our mortgage proposition by launching a range of products with a cashback incentive.</p>
    <p>Initially, the new cashback products will be available to UK customers looking to purchase a new property including first time buyers and home movers with a £500 cashback incentive upon selecting one of our new cashback rates. The incentive will be available on a selection of specific products and LTV’s, however, this could change at any time so please ensure that you are referring to the most up to date Mortgage Rate Sheet for the current incentives.</p>
    <p>If a customer chooses a cashback product they will receive a one-off cash back payment within 30 days of the drawdown of the loan. This cashback will be paid into the direct debit account for their mortgage.</p>
    <p>In addition to the launch of the cashback incentive we have also made the following changes:</p>

    <p><strong>UK Residential Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 70%, 75% and 80% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
      <li>3 Year Fixed Standard at 90% LTV has increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 90% LTV has increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 90% LTV has increased</li>
    </ul>

    <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 70%, 75% and 80% LTV have increased </li>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
      <li>3 Year Fixed Standard at 90% LTV has increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 90% LTV has increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 90% LTV has increased</li>
    </ul>

    <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 60% LTV has increased</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60% LTV has increased</li>
    </ul>

    <p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 60% LTV has increased</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60% LTV has increased</li>
    </ul>

    <p><strong>UK Buy to Let Remortgage Range</strong></p>
    <ul>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>

    <p><strong>UK Buy to Let Purchase Range</strong></p>
    <ul>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>

    <p>There are no other changes to our rates or fixed rate end dates.</p>
    <p>An updated mortgage rate sheet will be published on Monday 6 June 2022 to reflect these changes.</p>
    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate
      sheet</a></p>

  </NewsArticle>
);

export default Article;
